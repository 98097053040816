// extracted by mini-css-extract-plugin
export var archiveLinks = "compare-module--archiveLinks--35f74";
export var archiveLinksTitle = "compare-module--archiveLinksTitle--18c26";
export var archiveLinksWrap = "compare-module--archiveLinksWrap--2ad11";
export var compareInsurers = "compare-module--compareInsurers--37de7";
export var compareInsurersSelectWrap = "compare-module--compareInsurersSelectWrap--fd3a5";
export var paContainer = "compare-module--paContainer--53abc";
export var postArchive = "compare-module--postArchive--341a0";
export var postArchiveBreadcrumbs = "compare-module--postArchiveBreadcrumbs--7c48f";
export var postArchiveSelect = "compare-module--postArchiveSelect--bc0af";
export var postArchiveSelectWrap = "compare-module--postArchiveSelectWrap--58f56";
export var postArchiveText = "compare-module--postArchiveText--a8b29";
export var selectArrow = "compare-module--selectArrow--db752";
export var testimonial = "compare-module--testimonial--f674f";
export var testimonialAttr = "compare-module--testimonialAttr--11da8";
export var testimonialBtn = "compare-module--testimonialBtn--01e78";
export var testimonialImage = "compare-module--testimonialImage--42c5d";
export var testimonialLocation = "compare-module--testimonialLocation--d41b6";
export var testimonialName = "compare-module--testimonialName--2a781";
export var testimonialQuote = "compare-module--testimonialQuote--0f7d6";
export var testimonialSmall = "compare-module--testimonialSmall--53409";