import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/header/header'
import Footer from '../components/footer/footer'
import { useState, useEffect } from "react"
import Ratings from '../components/ratings/ratings'
import * as Styles from './compare.module.scss'
import c from 'classnames'

const WpPost = ({data}) => {
	const images = data.allWpMediaItem.edges;
	const [ item1, setItem1] = useState('');
	const [ link, setLink] = useState('');

	useEffect(() => {
		import('tiny-slider').then(({ tns }) => {
			setTimeout(() => {
				if (typeof window !== 'undefined') {
					const sliders = document.querySelectorAll('.slider-wrap');
		
					if (sliders) {
						for (let i = 0; i < sliders.length; i++) {
							var has_controls = sliders[i].dataset.controls ? sliders[i].dataset.controls : true;
				
							var slider = tns({
								container: sliders[i],
								mouseDrag: true,
								touch: true,
								responsive: {
									300: {
										items: sliders[i].dataset.smslides ? sliders[i].dataset.smslides : 1,
										loop: sliders[i].dataset.loop ? sliders[i].dataset.loop : false,
									},
									650: {
										items: sliders[i].dataset.mdslides ? sliders[i].dataset.mdslides : 1,
										loop: false,
									},
									1100: {
										items: sliders[i].dataset.slides ? sliders[i].dataset.slides : 1,
										loop: false,
									}
								},
								slideBy: 'page',
								nav: sliders[i].dataset.nav ? sliders[i].dataset.nav : false,
								controls: has_controls,
								loop: false,
								gutter: sliders[i].dataset.gutter ? sliders[i].dataset.gutter : 0
							});
						}
				
						const controls = document.querySelectorAll('.tns-controls');
				
						controls.forEach(control => {
							control.tabIndex = -1;
				
							var buttons = document.querySelectorAll('.tns-controls button');
				
							buttons.forEach(button => {
								button.tabIndex = 0;
							})
						});
					}
				}
			}, 1);
		})
	}, [])

	let myArray = [];

	var _ = require('lodash');

	data.wp.acfOptionsInsurer.compareInsurers.insurerComparisons.map((insurer, index) => {
		let insurer1 = insurer.insurer1.title;
		let insurer2 = insurer.insurer2.title;
		let link = insurer.pageLink;
		myArray.push({insurer1, insurer2, link});
	})

	var woDuplicates = _.uniqBy(myArray, 'insurer2');

	let bg = data.wpPage.featuredHero.heroImage.sourceUrl;
	let mobilebg = data.wpPage.featuredHero.heroImageMobile.sourceUrl;

	const nhhStyle = `		
	<style>
		.post-archive::before {
			background-image: url('${bg}') !important;
		}
	</style>`;

	const nhhMobStyle = `		
	<style>
		@media screen and (max-width: 600x) {
			.post-archive::before {
				background-image: url(''${mobilebg}')) !important;
			}
		}
	</style>`

	function populateSecond(target) {
		if (typeof window !== 'undefined') {
			let second = document.getElementById('insurer2');
			let button = document.getElementById('button');
			button.classList.add('btn--disabled');
			second.length = 1;
			setItem1(target);
			for (let index = 0; index < woDuplicates.length; index++) {
				const element = woDuplicates[index];
				if(element.insurer2 != target){
					var opt = document.createElement('option');
					opt.value = element.insurer2;
					opt.innerHTML = element.insurer2;
					second.appendChild(opt);
				}
			}
		}
	}

	function populateLink(target) {
		if (typeof window !== 'undefined') {
			let first = item1;
			let second = target;
			let button = document.getElementById('button');
			first = first.replace(" ", "-").toLowerCase();
			second = second.replace(" ", "-").toLowerCase();

			if(second != '') {
				button.classList.remove('btn--disabled');
				for (let index = 0; index < myArray.length; index++) {
					const element = myArray[index]['link']['uri'];
					if(element.includes(first) && element.includes(second)) {
						setLink(element);
					}
				}
			} else {
				button.classList.add('btn--disabled');
			}
		}
	}


	// setTimeout(() => {
	// 	if (typeof window !== 'undefined') {
	// 		let Choices = require('choices.js');
	// 		let sortSelect = document.getElementById('insurer1');
	// 		let starsSelect = document.getElementById('insurer2');

	// 		const sortChoices = new Choices(sortSelect, {
	// 			allowHTML: false
	// 		});
	// 		const starsChoices = new Choices(starsSelect, {
	// 			allowHTML: false
	// 		});
	// 	}
	// }, 500);

  return (
	<div>
		{/* <Helmet>
			<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/choices.js/public/assets/styles/choices.min.css" />
		</Helmet> */}
		<Layout data={data.wpPage.seo}></Layout>
		<main className="main" role="main">
			<div className={c(Styles.postArchive, 'post-archive')} id="main-content">
				{
					(bg) ? 
						<div dangerouslySetInnerHTML={{__html:nhhStyle}}/>  
					: ''
				}

				{
					(mobilebg) ? 
						<div dangerouslySetInnerHTML={{__html:nhhMobStyle}}/>  
					: ''
				}
				<div className={c('container', 'container--no-flex', Styles.paContainer)}>
					<div className={Styles.postArchiveText}>
						<h1>{data.wpPage.featuredHero.pageTitle}</h1>
						<p>{data.wpPage.featuredHero.introduction}</p>
					</div>
					<div className={Styles.compareInsurers} id="compare-insurers" style={{color:'black'}} data-insurers="<?php echo htmlspecialchars(json_encode($insurers)); ?>">
						<div className={c(Styles.compareInsurersSelectWrap, 'compareSelectWrap')}>
							<div className={Styles.selectArrow}>
								<select 
									data-trigger
									name="insurer1"
									id="insurer1" 
									className={Styles.formControl}
									onChange={e => populateSecond(e.target.value)}>
									<option value="" selected disabled>Select insurer</option>
									{
										woDuplicates.map((insurer, index) => {
											return (
												<option value={insurer.insurer2}>{insurer.insurer2}</option>
											)
										})
									}
								</select> 
							</div>
						<span>vs</span>
							<div className={Styles.selectArrow}>
								<select id="insurer2" onChange={e => populateLink(e.target.value)}>
									<option value="" id="placeholder" selected >Select insurer</option>
								</select> 
							</div>
						</div>
						<a href={link} id="button" className="btn btn--disabled btn--alt">See how they compare</a>
					</div>
					<div className={Styles.postArchiveBreadcrumbs}>
						<p className="breadcrumbs"><span><span><a href="https://www.petted.com">Home</a> &gt; <span><a href="https://www.petted.com">Pet Insurance</a> &gt; <span className="breadcrumb_last" aria-current="page">Compare Insurers</span></span></span></span></p>
					</div>
				</div>
			</div>

			<div className="section fix-overflow">
				<div className="container items-center"> 
					<div className="col col-md-2-5 align-text-center">
						<h2 className="text-block__title title--padded">Join thousands <br/>of happy pet parents!</h2>
						<div className="text-block__inner">
							<img loading='lazy' style={{maxWidth:'100px', margin:'0 auto', marginBottom:'10px'}} src="https://dev-petted2.pantheonsite.io/wp-content/themes/petted/compiled/images/petted-logo-dark-alt.svg" alt="Petted logo" className="menu-logo" />
							<div className="ratings">
								<Ratings info={data.wp.themeGeneralSettings.ratingsWidget}  title="Excellent"></Ratings>
							</div>
						</div>
					</div>
					<div className="col col-md-3-5 align-text-center testimonial-slider">
						<div className="slider-wrap">
							<div className={c('slide', Styles.testimonial)}>
								<p className={Styles.testimonialQuote}>Getting a quote through Petted was a breeze. In less than 5 minutes my new pup was fully covered giving me total piece of mind!</p>
								<footer>
									<img loading='lazy' src="https://dev-petted2.pantheonsite.io/wp-content/uploads/2022/01/ninat.jpg" alt="Dog" className={Styles.testimonialImage} />
									<div className={Styles.testimonialAttr}>
										<span className={Styles.testimonialName}>Cassie D</span>
										<span className={Styles.testimonialLocation}>Los Angeles</span>
									</div>
								</footer>
							</div>
							<div className={c('slide', Styles.testimonial)}>
								<p className={Styles.testimonialQuote}>My six-year-old German Shepherd Mica broke his left radius bone when he took a tumble while hiking in Jacks Peak Park. The vet bill was $2,150. I'm so glad I had insurance - it only cost me $215 in co-pay.</p>
								<footer>
									<img loading='lazy' src="https://dev-petted2.pantheonsite.io/wp-content/uploads/2022/02/quote.png" alt="Dog" className={Styles.testimonialImage} />
									<div className={Styles.testimonialAttr}>
										<span className={Styles.testimonialName}>Hannah</span>
										<span className={Styles.testimonialLocation}>Carmel</span>
									</div>
								</footer>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section">
				<div className={c(Styles.archiveLinks, 'container')}>
					<div className={Styles.archiveLinksTitle}>
						<h3>Compare the top pet insurance&nbsp;providers</h3>
					</div>
					<div className={Styles.archiveLinksWrap}>
						{
							(data?.wp?.acfOptionsInsurer?.compareInsurers?.insurerComparisons) ?
							data.wp.acfOptionsInsurer.compareInsurers.insurerComparisons.map((insurerComparison, index) => {
								return (
									<a key={index} href={insurerComparison.pageLink.uri}>{insurerComparison.insurer1?.title} vs. {insurerComparison.insurer2?.title}</a>
								)
							}
							) : null
						}
					</div>
				</div>
			</div>
		</main>
        <Footer data={data.wp.acfOptionsFooter.footerOptions}></Footer>
    </div>
  )
}

export const query = graphql`
    query ($id: String) {
		wpPage(id: { eq: $id }) {
			blocksJSON
			featuredHero {
				enableFeaturedHero
				fieldGroupName
				heroVideoId
				introduction
				pageTitle
				showBreadcrumbs
				heroImage {
					sourceUrl
					altText
				}
				heroImageMobile {
					sourceUrl
				}
			}
			seo {
				breadcrumbs {
					text
					url
				}
				fullHead
				schema {
					raw
				}
			}
			hero {
				heroContent
				heroButtonText
				heroButtonLink {
					  url
				}
				heroImage {
					  sourceUrl
					  altText
				}
				heroMobileImage {
					  sourceUrl
				}
			}
			noneHomeHeader {
				nhhMoveImageDown
				nhhBackgroundImage {
					sourceUrl
				}
				nhhBackgroundImageMobile {
					sourceUrl
				}
				nhhImage {
					sourceUrl
					altText
				}
			}
			pageSettings {
				altHeaderWave
				noContainerOnMain
			}
		}
		wp {
			themeGeneralSettings {
				ratingsWidget {
				  ratingName
				  ratingNumber
				  ratingStars
				  ratingValue
				  ratingLink
				}
			}
			acfOptionsFooter {
				footerOptions {
					copyrightMessage
					facebook
					fieldGroupName
					footerLogo {
						sourceUrl
					}
					instagram
					tagline
					tiktok
				}
			}
			acfOptionsInsurer {
				pageSlug
				pageTitle
				compareInsurers {
					insurerComparisons {
						insurer1 {
							... on WpInsurer {
								title
							}
						}
						insurer2 {
							... on WpInsurer {
								title
							}
						}
						pageLink {
							... on WpPage {
								uri
							}
						}
					}
				}
			}
		}
		allWpMediaItem {
			edges {
				node {
					id
					sourceUrl
					databaseId
				}
			}
	  	}
	}
`

export default WpPost