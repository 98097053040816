import * as React from 'react'
import * as Styles from './ratings.module.scss'
import c from 'classnames'

const Layout = ({ info, title }) => {
	let ratingName = info.ratingName;
	let ratingNumber = info.ratingNumber;
	let ratingStars = info.ratingStars;
	let ratingValue = info.ratingValue;
	let ratingLink = info.ratingLink;

	let stars = '';
	for (let index = 0; index < ratingStars; index++) {
		stars += '<img src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjE5IiB3aWR0aD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2LjU0MiA4LjYxN2MuMTktLjE2Ni4zMzItLjM5OC40MDQtLjY2My4wNzItLjI2NS4wNzItLjU1LS4wMDEtLjgxNS0uMDczLS4yNjUtLjIxNS0uNDk3LS40MDYtLjY2MnMtLjQyMS0uMjU4LS42NTktLjI2MmwtNC42MTYtLjIwNGEuMTA4LjEwOCAwIDAxLS4wNjItLjAyOC4xMzkuMTM5IDAgMDEtLjA0LS4wNjJMOS41NjcuODc1QTEuMzI4IDEuMzI4IDAgMDA5LjE1LjI0MSAxLjAyOCAxLjAyOCAwIDAwOC40OTcgMGMtLjIzMyAwLS40Ni4wODQtLjY1Mi4yNGExLjMyOCAxLjMyOCAwIDAwLS40MTcuNjM1TDUuODM4IDUuOTRhLjEzOS4xMzkgMCAwMS0uMDQuMDYyLjEwOC4xMDggMCAwMS0uMDYyLjAyOGwtNC42MTYuMjA0Yy0uMjM4LjAwNC0uNDY4LjA5Ni0uNjYuMjYycy0uMzMyLjM5Ny0uNDA1LjY2MmMtLjA3My4yNjUtLjA3My41NS0uMDAxLjgxNS4wNzIuMjY1LjIxNC40OTcuNDA0LjY2M0w0LjA4IDExLjk3Yy4wMTguMDE2LjAzMi4wMzkuMDQuMDY0cy4wMDcuMDU0LjAwMS4wOGwtMS4yNDYgNS4xNzFjLS4wNjUuMjYzLS4wNTguNTQ0LjAxOC44MDNzLjIxOS40ODQuNDA4LjY0NS40MTcuMjQ5LjY1LjI1M2MuMjM0LjAwNC40NjQtLjA3Ni42NTctLjIzbDMuODI2LTMuMDAzYS4xMDEuMTAxIDAgMDEuMDY0LS4wMjNjLjAyMyAwIC4wNDYuMDA4LjA2NS4wMjNsMy44MjUgMy4wMDNjLjE5MS4xNi40Mi4yNDUuNjU0LjI0NS4yMzUgMCAuNDYzLS4wODYuNjU0LS4yNDUuMTktLjE1OS4zMzItLjM4My40MDktLjY0MS4wNzYtLjI1OC4wODItLjUzOC4wMTctLjhsLTEuMjU3LTUuMTlhLjE1NC4xNTQgMCAwMS4wMDEtLjA3OS4xMzIuMTMyIDAgMDEuMDQtLjA2NHoiIGZpbGw9IiNmZmQ4NGYiLz48L3N2Zz4=" alt="star" width="17" height="17" />';
	}

	return (
		<div className={c(Styles.ratings, 'ratings')}>
			{
				(title) ?  <h5 class='no-bm'>{title}</h5> : ''
			}
			{
				(ratingLink) ?  "<a href={ratingLink} target='_blank'>" : ''
			}
				<span className={Styles.ratingsTitle}>{ratingName}</span>
				<div className={c(Styles.ratingsInner, 'flex', 'items-center')}>
					<div className={c(Styles.ratingsStars, 'flex', 'items-center')} dangerouslySetInnerHTML={{__html: stars}}></div>
					<span className={Styles.ratingsVal}>{ratingValue}</span>
					<span className={Styles.ratingsNum}>{ratingNumber} ratings</span>
				</div>
				{
					(ratingLink) ?  "</a>" : ''
				}
		</div>
	)
}

export default Layout